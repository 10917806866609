import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectLastDBAutoExport, setLastDBAutoExport } from "../store/reducers/settingsSlice";
import { useCallback, useMemo } from "react";
import { Event, EventHistory, Transaction, User } from "../interfaces";
import { selectUsers } from "../store/reducers/userSlice";
import { selectTransactions } from "../store/reducers/transactionSlice";
import { saveDBAsFile } from "../components/Export/Export";
import { selectEventHistory, selectEvents } from "../store/reducers/eventSlice";

export function useAutoExport(): [() => void] {
    const lastDBAutoExportDateStr: string | undefined = useAppSelector(selectLastDBAutoExport);
    const users: User[] = useAppSelector(selectUsers);
    const transactions: Transaction[] = useAppSelector(selectTransactions);
    const events: Event[] = useAppSelector(selectEvents);
    const eventHistory: EventHistory = useAppSelector(selectEventHistory);
    const storeDispatch = useAppDispatch();

    const isAutoExportEnable: boolean = useMemo(() => {
        if (!lastDBAutoExportDateStr) {
            return true;
        }
        const today: Date = new Date();
        today.setHours(0, 0, 0, 0);
        const lastDBAutoExportDate: Date = new Date(lastDBAutoExportDateStr);
        lastDBAutoExportDate.setHours(0, 0, 0, 0);
        return today.getTime() !== lastDBAutoExportDate.getTime();
    }, [lastDBAutoExportDateStr]);

    const doAutoExport: () => void = useCallback(() => {
        if (!isAutoExportEnable) {
            return;
        }
        saveDBAsFile(users, transactions, events, eventHistory);
        storeDispatch(setLastDBAutoExport((new Date()).toISOString()));
    }, [isAutoExportEnable, storeDispatch, users, transactions, events, eventHistory]);

    return [doAutoExport];
}
