import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from "react";

export interface Props {
    open: boolean
    onClose: () => void
}

const InfoDialog = ({ onClose, open }: Props) => {
    const [version, setVersion] = useState<string>('');

    useEffect(() => {
        if (open) {
            const broadcast = new BroadcastChannel('count-channel');

            broadcast.onmessage = (event) => {
                setVersion(event.data);
            };

            broadcast.postMessage({
                type: 'GET_VERSION',
            });
        }
    }, [open])

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogContent>
                <p>Версия: {version.match(/\d+[.]\d+[.]\d+/g) || 'Не определена'}</p>
            </DialogContent>
        </Dialog>
    );
}

export default InfoDialog;
