import { Dialog, DialogTitle, DialogContent, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as React from "react";
import { FC, useCallback } from "react";

interface MenuDialogProps {
    open: boolean;
    onClose: () => void;
    message: string;
    onEdit: () => void;
    onRemove: () => void;
}

export const MenuDialog: FC<MenuDialogProps> = ({
    open,
    onClose,
    message,
    onEdit,
    onRemove,
}) => {

    const handleEdit = useCallback(() => {
        onClose();
        onEdit();
    }, [onClose, onEdit]);

    const handleRemove = useCallback(() => {
        onClose();
        onRemove();
    }, [onClose, onRemove]);

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>{message}</DialogTitle>
            <DialogContent>
                <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Редактировать</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleRemove}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Удалить</ListItemText>
                </MenuItem>
            </DialogContent>
        </Dialog>
    )
}
