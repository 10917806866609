import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectTransaction, remove, update } from "../../../store/reducers/transactionSlice";
import { Category, Transaction } from "../../../interfaces";
import { selectUserName } from "../../../store/reducers/userSlice";
import Chip from '@mui/material/Chip';
import { CATEGORIES, USER_ME, USER_SHOP } from "../../../constants";
import TransactionDialog from "../../Home/ContainerTransactions/DraggableBox/TransactionDialog/TransactionDialog";
import { MenuDialog } from "./MenuDialog/MenuDialog";
import { useLongPress } from "../../../hooks/useLongPress";
import { currencyFormat } from "../../../utils/currencyFormat";

interface Props {
    id: number
}

const TransactionRow = ({ id }: Props) => {
    const storeDispatch = useAppDispatch();
    const transaction: Transaction | undefined = useAppSelector((state) => selectTransaction(state, id));
    const creditorName: string = useAppSelector((state) => {
        if (!transaction)
            return '(Удалён)';
        return selectUserName(state, transaction.creditorId)
    });
    const borrowerName: string = useAppSelector((state) => {
        if (!transaction)
            return '(Удалён)';
        return selectUserName(state, transaction.borrowerId)
    });
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const titleRow = useMemo(() => {
        if (!transaction)
            return null;
        const isTransactionBetweenUsers: boolean = transaction.borrowerId === USER_SHOP.id;
        if (isTransactionBetweenUsers)
            return 'Покупка ';

        const userName: string = transaction.creditorId !== USER_ME.id ? creditorName : borrowerName;
        const action: string = transaction.creditorId !== USER_ME.id ? 'ждёт' : 'торчит';

        return (
            <>
                <span className='transactionRowValue'>
                    {userName}
                </span>
                {' '}
                {action}
                {' '}
            </>
        )
    }, [transaction, creditorName, borrowerName]);

    const dateAndCategoryRow: JSX.Element | null = useMemo(() => {
        if (!transaction || !transaction.date)
            return null;
        const dateStr: string = (new Date(transaction.date)).toLocaleString("ru").slice(0, -3);
        const category: Category | undefined = transaction.categoryId
            ? CATEGORIES.find((cat) => cat.id === transaction.categoryId)
            : undefined;
        return <div className='transactionRowAdditional'>
            {dateStr}
            {category && (
                <Chip
                    label={category.title}
                    size='small'
                    style={{ backgroundColor: category.color, margin: '0 0.3em', height: '1em' }}
                />
            )}
        </div>
    }, [transaction]);

    const handleDelete = useCallback(() => {
        if (window.confirm('Точно удалить?'))
            storeDispatch(remove(id))
    }, [storeDispatch, id]);

    const handleEdit = useCallback((value: number, comment?: string, categoryId?: number) => {
        if (!transaction)
            return;
        storeDispatch(update({
            ...transaction,
            value,
            comment,
            categoryId
        }))
    }, [storeDispatch, transaction]);

    const handleLongPress = () => {
        setOpenMenu(true);
    }

    const longPressHandlers = useLongPress({
        onLongPress: handleLongPress
    });

    if (!transaction)
        return null;

    return (
        <>
        <div className='transactionRow' {...longPressHandlers}>
            <div>
                {titleRow}
                <span className='transactionRowValue'>
                    {currencyFormat(transaction.value)}р
                </span>
                {dateAndCategoryRow}
            </div>
            {transaction.comment && (
                <div className='transactionRowAdditional'>
                    {transaction.comment}
                </div>
            )}
        </div>
        <TransactionDialog
            open={openEditDialog}
            onClose={() => setOpenEditDialog(false)}
            onEnter={handleEdit}
            valueDefault={transaction.value}
            commentDefault={transaction.comment}
        />
        <MenuDialog
            open={openMenu}
            onClose={() => setOpenMenu(false)}
            message=''
            onEdit={() => setOpenEditDialog(true)}
            onRemove={handleDelete}
        />
        </>
    )
}

export default TransactionRow;
