import React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import OutlinedInput from "@mui/material/OutlinedInput";

interface Props {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}

const Search = ({ value, onChange, placeholder }: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    }

    const handleClear = () => {
        onChange('');
    }

    const endAdornment = value !== ''
    ? (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClear}
            >
                <ClearIcon />
            </IconButton>
        </InputAdornment>
    ) : null;

    return (
        <OutlinedInput
            placeholder={placeholder || 'Поиск'}
            id="outlined-adornment-password"
            type='text'
            value={value}
            onChange={handleChange}
            endAdornment={endAdornment}
        />
    )
}

export default Search;
