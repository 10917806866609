import React from "react";
import { FC, memo, useMemo } from 'react'
import { useAppSelector } from "../../../store/hooks";
import { selectUserName } from "../../../store/reducers/userSlice";
import { selectUserTransactionsSum } from "../../../store/reducers/transactionSlice";
import { USER_ME } from "../../../constants";
import { selectMoveMode } from "../../../store/reducers/settingsSlice";
import { currencyFormat } from "../../../utils/currencyFormat";

export interface BoxProps {
    userId: number
    preview?: boolean
    previewTransaction?: boolean
    isActive?: boolean
    canDrop?: boolean
    onClick?: () => void
}

export const UserBox: FC<BoxProps> = memo(function Box({
    userId,
    preview,
    previewTransaction,
    isActive,
    canDrop,
    onClick
}) {
    const name: string = useAppSelector((state) => selectUserName(state, userId));
    const sum: number = useAppSelector((state) => selectUserTransactionsSum(state, userId));
    const moveMode: boolean = useAppSelector(selectMoveMode);

    const isMe: boolean = useMemo(() => (userId === USER_ME.id), [userId])

    const sumStr: string | null = useMemo(() => {
        if (isMe)
            return null;
        if (sum === 0)
            return null;
        if (sum > 0)
            return `Ждёт ${currencyFormat(sum)}р`;
        return `Торчит ${currencyFormat(Math.abs(sum))}р`
    }, [isMe, sum]);

    if (previewTransaction)
        return (
            <div
                className={`userBox userBox_preview`}
            >
                <div style={{visibility: 'hidden'}}>
                    {name}
                </div>
                <div className='userBox__sum' style={{visibility: 'hidden'}}>
                    {sumStr}
                </div>
            </div>
        );

    return (
        <div
            className={`userBox${
                isActive ? ' userBox_active' : ''
            }${
                canDrop ? ' userBox_canDrop' : ''
            }${
                moveMode ? ' userBox_wiggle' : ''
            }`}
            onClick={onClick}
        >
            <div>
                {name}
            </div>
            <div className='userBox__sum'>
                {sumStr}
            </div>
        </div>
    )
})
