import React, { MouseEvent } from "react";
import AddUserButton from "./AddUserButton/AddUserButton";
import MoveModeButton from "./MoveModeButton/MoveModeButton";
import Export from "../Export/Export";
import Import from "../Import/Import";
import ReloadButton from "./ReloadButton/ReloadButton";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ResetPositionsButton from "./ResetPositionsButton/ResetPositionsButton";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectIsMenuOpen, setMenuAnchorEl } from "../../store/reducers/settingsSlice";
import InfoButton from "./InfoButton/InfoButton";
import ExportTransactions from "../ExportTransactions/ExportTransactions";
import ImportAdditional from "../ImportAdditional/ImportAdditional";
import GitSettingsButton from "./GitSettingsButton/GitSettingsButton";

const ControlPanel = () => {
    const menuAnchorEl = useAppSelector(selectIsMenuOpen);
    const storeDispatch = useAppDispatch();

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        storeDispatch(setMenuAnchorEl(event.currentTarget));
    };

    const handleCloseMenu = () => {
        storeDispatch(setMenuAnchorEl(null));
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={menuAnchorEl ? 'long-menu' : undefined}
                aria-expanded={menuAnchorEl ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpenMenu}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl}
                onClose={handleCloseMenu}
            >
                <AddUserButton />
                <Export />
                <ExportTransactions />
                <Import />
                <ImportAdditional />
                <MoveModeButton />
                <ResetPositionsButton />
                <ReloadButton />
                <GitSettingsButton />
                <InfoButton />
            </Menu>
        </div>
    )
}

export default ControlPanel;
