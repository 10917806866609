import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from './reducers/userSlice';
import transactionReducer from './reducers/transactionSlice';
import settingsReducer from './reducers/settingsSlice';
import eventReducer from './reducers/eventSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        transaction: transactionReducer,
        settings: settingsReducer,
        event: eventReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
