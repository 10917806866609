import { FC } from 'react'
import { DraggableBox } from './DraggableBox/DraggableBox'
import { User } from "../../../interfaces";
import { useAppSelector } from "../../../store/hooks";
import { selectUsers } from "../../../store/reducers/userSlice";
import RecentTransactions from "../UserBox/RecentTransactions/RecentTransactions";

export const ContainerTransactions: FC = () => {
    const users: User[] = useAppSelector(selectUsers);

    return (
        <div className='container' style={{ position: 'relative' }}>
            {users.map((user) => (
                <DraggableBox
                    user={user}
                    key={user.id}
                />
            ))}
            <RecentTransactions />
        </div>
    )
}
