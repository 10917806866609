import { FC, CSSProperties, memo } from 'react'
import { UserBox } from '../../UserBox/UserBox'

const styles: CSSProperties = {
    display: 'inline-block',
    // transform: 'rotate(-7deg)',
    // WebkitTransform: 'rotate(-7deg)',
}

export interface BoxPreviewMoveProps {
    userId: number
}

export const BoxPreviewMove: FC<BoxPreviewMoveProps> = memo(
    function BoxPreviewMove({ userId }) {
        return (
            <div style={styles}>
                <UserBox userId={userId} preview />
            </div>
        )
    },
)
