import { useCallback, useMemo, useRef, MouseEvent, TouchEvent } from "react";

const LONG_PRESS_DELAY = 500;

interface Coords {
    x: number,
    y: number,
}

interface UseLongPressProps {
    onClick?: () => void;
    onLongPress?: () => void;
}

export const useLongPress = ({
    onClick = () => {},
    onLongPress = () => {},
}: UseLongPressProps) => {
    const timerId = useRef<number | null>(null);
    const startCoords = useRef<Coords | null>(null);
    const moved = useRef<boolean>(false);

    const callback = useCallback(() => {
        onLongPress();
        timerId.current = null;
    }, [onLongPress]);

    const start = useCallback((e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        moved.current = false;
        timerId.current = window.setTimeout(callback, LONG_PRESS_DELAY);
        startCoords.current = {
            x: 'touches' in e ? e.touches[0].clientX : e.clientX,
            y: 'touches' in e ? e.touches[0].clientY : e.clientY,
        }
    }, [callback]);

    const stop = useCallback((e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        startCoords.current = null;
        if (timerId.current) {
            window.clearTimeout(timerId.current);
            if (!moved.current) {
                onClick();
            }
            timerId.current = null;
        }
    }, [onClick]);

    const move = useCallback((e: MouseEvent | TouchEvent) => {
        if (!startCoords.current) {
            return;
        }
        const coords: Coords = {
            x: 'touches' in e ? e.touches[0].clientX : e.clientX,
            y: 'touches' in e ? e.touches[0].clientY : e.clientY,
        }
        const dif = Math.pow(coords.x - startCoords.current.x, 2) + Math.pow(coords.y - startCoords.current.y, 2);
        if (dif > 4) {
            moved.current = true;
            stop(e);
        }
    }, [startCoords, moved, stop]);

    return useMemo(() => ({
        onMouseDown: start,
        onMouseUp: stop,
        onMouseLeave: stop,
        onMouseMove: move,
        onTouchStart: start,
        onTouchEnd: stop,
        onTouchMove: move,
    }), [start, stop, move]);
}
