import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Coords, User } from "../../interfaces";
import { USER_ME, USER_SHOP } from "../../constants";

export interface UserState {
    users: User[]
}

const initialState: UserState = {
    users: [
        USER_ME
    ]
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<User[]>) => {
            state.users = action.payload;
        },
        add: (state, action: PayloadAction<User>) => {
            state.users.push(action.payload);
        },
        remove: (state, action: PayloadAction<number>) => {
            if (action.payload === USER_ME.id || action.payload === USER_SHOP.id)
                return;
            state.users = state.users.filter((user) => user.id !== action.payload);
        },
        setCoords: (state, action: PayloadAction<{ id: number, coords: Coords }>) => {
            const userIndex: number = state.users.findIndex((user) => user.id === action.payload.id);
            if (userIndex < 0)
                return;
            state.users[userIndex] = {
                ...state.users[userIndex],
                top: action.payload.coords.top,
                left: action.payload.coords.left
            };
        },
        resetAllCoords: (state) => {
            state.users = state.users.map((user) => ({...user, top: 0, left: 0}));
        },
        setName: (state, action: PayloadAction<{ id: number, name: string }>) => {
            const userIndex: number = state.users.findIndex((user) => user.id === action.payload.id);
            if (userIndex < 0)
                return;
            state.users[userIndex] = {
                ...state.users[userIndex],
                name: action.payload.name,
            };
        },
    },
});

export const { set, add, remove, setCoords, resetAllCoords, setName } = userSlice.actions;

export const selectUsers = (state: RootState) => state.user.users;
// export const selectUser = (state: RootState, id: number): Transaction | undefined => (
//     state.user.users.find((user) => (
//         user.id === id
//     ))
// );
export const selectUserName = (state: RootState, id: number): string => {
    const user: User | undefined = state.user.users.find((user) => user.id === id);
    if (!user)
        return '';
    return user.name;
};

export default userSlice.reducer;
