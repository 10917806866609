import { styled } from "@mui/material/styles";

export const CalendarWrapper = styled('div')({
    flex: 2,
    overflow: 'auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

export const CalendarGrid = styled('div')({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
});

export const WeekRow = styled('div')({
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    flex: 1,
});

export const DayCell = styled('div')({
    flex: 1,
    margin: '1px',
    overflow: 'hidden',
    fontSize: '0.75rem',
    padding: '2px',
    display: 'flex',
    flexWrap: 'wrap',
});
