import { FC, useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../ItemTypes'
import { DraggableBox } from "./DraggableBox/DraggableBox";
import { DragItem, User } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectUsers } from "../../../store/reducers/userSlice";
import { setCoords } from "../../../store/reducers/userSlice"

export const ContainerMove: FC = () => {
    const users: User[] = useAppSelector(selectUsers);
    const storeDispatch = useAppDispatch();

    const moveBox = useCallback((id: number, left: number, top: number) => {
        storeDispatch(setCoords({
            id,
            coords: {
                top,
                left
            }
        }))
    },
    [storeDispatch])

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.BOX_MOVE,
            drop(item: DragItem, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset() as {
                    x: number
                    y: number
                }

                let left = Math.round(item.left + delta.x)
                let top = Math.round(item.top + delta.y)

                moveBox(item.id, left, top)
                return undefined
            },
        }),
        [moveBox],
    )

    return (
        <div ref={drop} className='container'>
            {users.map((user) => (
                <DraggableBox
                    id={user.id}
                    title={user.name}
                    top={user.top}
                    left={user.left}
                    key={user.id}
                />
            ))}
        </div>
    )
}
