import { Dispatch, SetStateAction } from "react";
import { Event, EventHistory } from "../../../interfaces";

export const getFirstDayOfMonth = (year: number, month: number) => {
    return new Date(year, month, 7).getDay()
};

export const getLastDateOfMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
};

export const getLastDayOfLastMonth = (year: number, month: number) => {
    if (month === 0) {
        return new Date(year - 1, 11, 31).getDate()
    }

    return new Date(year, month, 0).getDate();
};

export const setPreviousMonth = (
    month: number,
    setMonth: Dispatch<SetStateAction<number>>,
    setYear: Dispatch<SetStateAction<number>>,
) => {
    if ( month === 0 ) {
        setMonth(11);
        setYear((year) => year - 1);
    }
    else {
        setMonth(month - 1);
    }
};

export const setNextMonth = (
    month: number,
    setMonth: Dispatch<SetStateAction<number>>,
    setYear: Dispatch<SetStateAction<number>>,
) => {
    if ( month === 11 ) {
        setMonth(0);
        setYear((year) => year + 1);
    }
    else {
        setMonth(month + 1);
    }
};

export const getDatesArray = (year: number, month: number) => {
    const firstDayOfMonth = getFirstDayOfMonth(year, month);
    const lastDateOfMonth = getLastDateOfMonth(year, month);
    const lastDayOfLastMonth = getLastDayOfLastMonth(year, month);

    let daysCounter = 0;

    let prevMonthDays = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
        daysCounter++;
        const dayNumber = lastDayOfLastMonth - firstDayOfMonth + 1 + i;
        const monthNumber = month === 0 ? 11 : month - 1;
        const yearNumber = month === 0 ? year - 1 : year;
        prevMonthDays.push(new Date(yearNumber, monthNumber, dayNumber));
    }

    let monthDays = [];
    for (let i = 1; i <= lastDateOfMonth; i++) {
        daysCounter++;
        monthDays.push(new Date(year, month, i));
    }

    let nextMonthDays = [];
    for (let i = 1; daysCounter < 42; i++) {
        daysCounter++;
        const monthNumber = month === 11 ? 0 : month + 1;
        const yearNumber = month === 11 ? year + 1 : year;
        nextMonthDays.push(new Date(yearNumber, monthNumber, i));
    }

    return [...prevMonthDays, ...monthDays, ...nextMonthDays];
}

export const getDatesByWeeksArray = (year: number, month: number): Date[][] => {
    const days = getDatesArray(year, month);

    return [0, 1, 2, 3, 4, 5].map((week) => (
        days.slice(week * 7, (week + 1) * 7)
    ));
}

export const getEventsByDay = (events: Event[], eventHistory: EventHistory, historyKey: string): ({ id: number, event: Event, count?: number })[] => {
    const eventItems = eventHistory[historyKey];
    if (!eventItems) {
        return [];
    }
    let result: ({ id: number, event: Event, count?: number })[] = [];
    eventItems.forEach(({ id, eventId, count }) => {
        const event = events.find((event) => event.id === eventId);
        if (event) {
            result.push({ id, event, count });
        }
    })

    return result;
}

export const getCellStyle = (isAnotherMonth: boolean, isToday: boolean, isSelected: boolean, isWeekend: boolean) => ({
    color: isAnotherMonth ? 'gray' : 'black',
    fontWeight: isToday ? 'bold' : 'normal',
    background: isAnotherMonth
        ? isSelected ? 'rgba(178, 108, 255, 0.4)' : isWeekend ? 'rgba(0, 145, 255, 0.05)' : 'rgba(255, 255, 255, 0.05)'
        : isSelected ? 'rgba(178, 108, 255, 0.4)' : isWeekend ? 'rgba(218, 223, 255, 0.4)' : 'rgba(255, 255, 255, 0.4)',
})
