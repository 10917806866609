import { Category, User, Event } from "./interfaces";

export const VERSION: string = '0.0.1';

export const USER_ME: User = {
    id: 0,
    name: 'Я',
    top: 100,
    left: 50,
}

export const EVENT_DEFAULT: Event = {
    id: (new Date('2000-01-01')).getTime(),
    title: 'Пример',
    icon: 'Star',
}

export const USER_SHOP: User = {
    id: -1,
    name: 'Магазины',
    top: 100,
    left: 50,
}

export const RECENT_TRANSACTION_VIEW_TIME: number = 3000;
export const RECENT_TRANSACTION_UPDATE_TIME: number = RECENT_TRANSACTION_VIEW_TIME + 100;

export const CATEGORIES: Category[] = [
    {
        id: 1,
        title: 'Еда',
        color: '#fcffcc'
    },
    {
        id: 2,
        title: 'Хоз',
        color: '#f1f1f1'
    },
    {
        id: 3,
        title: 'Трансп',
        color: '#d8e2ff'
    },
    {
        id: 4,
        title: 'Связь',
        color: '#fff9da'
    },
    {
        id: 5,
        title: 'Кайф',
        color: '#f9ddff'
    },
    {
        id: 6,
        title: 'Зоо',
    },
    {
        id: 7,
        title: 'Культ',
    },
    {
        id: 8,
        title: 'Дар'
    },
    {
        id: 9,
        title: 'Здоров',
        color: '#e7ffe0'
    },
    {
        id: 10,
        title: 'Коммун'
    },
    {
        id: 11,
        title: 'Од.об.'
    },
    {
        id: 12,
        title: 'Прочее'
    },
].reverse();
