import { EVENT_DEFAULT, USER_ME } from "../constants";
import { Transaction, User, Event, EventHistory } from "../interfaces";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectUsers, set as setUsers } from "../store/reducers/userSlice";
import { selectTransactions, set as setTransactions } from "../store/reducers/transactionSlice";
import {
    GitSettings,
    selectGitSettings,
    selectLastDBAutoExport,
    setLastDBAutoExport,
    setGit,
} from "../store/reducers/settingsSlice";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { getFromDB, useSaveToDB } from "./helpers";
import { selectEventHistory, selectEvents, set as setEvents, setEventHistory } from "../store/reducers/eventSlice";
import { useAutoExport } from "../hooks/useAutoExport";
import {useGit} from "../hooks/useGit";
import {getProjectDataJsonString} from "../utils/getProjectDataJsonString";

export const useIndexDB = () => {
    const users: User[] = useAppSelector(selectUsers);
    const transactions: Transaction[] = useAppSelector(selectTransactions);
    const events: Event[] = useAppSelector(selectEvents);
    const eventHistory: EventHistory = useAppSelector(selectEventHistory);
    const lastDBAutoExportDateStr: string | undefined = useAppSelector(selectLastDBAutoExport);
    const gitSettings: GitSettings | undefined = useAppSelector(selectGitSettings);
    const storeDispatch = useAppDispatch();
    const isFirstLoading = useRef<boolean>(true);
    const [doAutoExport] = useAutoExport();
    const { gitEnable, createDataCommit } = useGit();

    const {isLoading: isLoadingSaveUsers, isSaved: isUsersSaved, saveToDB: saveUsers} = useSaveToDB('users', users, isFirstLoading.current);
    const {isLoading: isLoadingSaveTransactions,  isSaved: isTransactionsSaved, saveToDB: saveTransactions} = useSaveToDB('transactions', transactions, isFirstLoading.current);
    const {isLoading: isLoadingEvents,  isSaved: isEventsSaved, saveToDB: saveEvents} = useSaveToDB('events', events, isFirstLoading.current);
    const {isLoading: isLoadingEventHistory,  isSaved: isEventHistorySaved, saveToDB: saveEventHistory} = useSaveToDB('eventHistory', eventHistory, isFirstLoading.current);
    const {isLoading: isLoadingSaveLastDBAutoExport} = useSaveToDB('lastDBAutoExportDateStr', lastDBAutoExportDateStr, isFirstLoading.current);
    const {isLoading: isLoadingSaveGitSettings, saveToDB: saveGitSettings} = useSaveToDB('gitSettings', gitSettings, isFirstLoading.current);

    const isLoading: boolean = isLoadingSaveUsers || isLoadingSaveTransactions || isLoadingEvents || isLoadingEventHistory || isLoadingSaveLastDBAutoExport || isLoadingSaveGitSettings;

    const needToSave: boolean = useMemo(() => (
        !isUsersSaved || !isTransactionsSaved || !isEventsSaved || !isEventHistorySaved || !saveEventHistory
    ), [isUsersSaved, isTransactionsSaved, isEventsSaved, isEventHistorySaved, saveEventHistory]);

    const dbToStore = useCallback(async () => {
        await getFromDB('users', (value) => storeDispatch(setUsers(value)), [USER_ME]);
        await getFromDB('transactions', (value) => storeDispatch(setTransactions(value)), []);
        await getFromDB('events', (value) => storeDispatch(setEvents(value)), [EVENT_DEFAULT]);
        await getFromDB('eventHistory', (value) => storeDispatch(setEventHistory(value)), {});
        await getFromDB('lastDBAutoExportDateStr', (value) => storeDispatch(setLastDBAutoExport(value)), undefined);
        await getFromDB('gitSettings', (value) => storeDispatch(setGit(value)), {
            owner: '',
            repo: '',
            token: '',
            id: '',
        });
        isFirstLoading.current = false;
    }, [storeDispatch]);

    const handleManualSave = useCallback(() => {
        if (typeof saveUsers === 'function') {
            saveUsers(users);
        }
        if (typeof saveTransactions === 'function') {
            saveTransactions(transactions);
        }
        if (typeof saveEvents === 'function') {
            saveEvents(events);
        }
        if (typeof saveEventHistory === 'function') {
            saveEventHistory(eventHistory);
        }
        if (typeof saveGitSettings === 'function') {
            saveGitSettings(gitSettings);
        }
    }, [users, transactions, events, eventHistory, gitSettings, saveUsers, saveTransactions, saveEvents, saveEventHistory, saveGitSettings]);

    useEffect(() => {
        if (isFirstLoading.current) {
            dbToStore();
        }
    }, [dbToStore]);

    useEffect(() => {
        if (!isFirstLoading.current) {
            doAutoExport();
            if (gitEnable) {
                const jsonStr = btoa(encodeURIComponent((getProjectDataJsonString(users, transactions, events, eventHistory))));
                // to decode: decodeURIComponent(atob(jsonStr))
                createDataCommit(jsonStr);
            }
        }
    }, [users, transactions, events, eventHistory, doAutoExport, isFirstLoading, gitEnable, createDataCommit]);

    return { isLoading, needToSave, handleManualSave };
}
