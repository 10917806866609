import * as React from "react";
import { ChangeEvent, FC } from "react";
import { selectUsers, set as setUsers } from "../../store/reducers/userSlice";
import { selectTransactions, set as setTransactions } from "../../store/reducers/transactionSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Event, EventHistory, Transaction, User } from "../../interfaces";
import { saveDBAsFile } from "../Export/Export";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import { setMenuAnchorEl } from "../../store/reducers/settingsSlice";
import { selectEventHistory, selectEvents, set as setEvents, setEventHistory } from "../../store/reducers/eventSlice";

const Import: FC = () => {
    const storeDispatch = useAppDispatch();
    const users: User[] = useAppSelector(selectUsers);
    const transactions: Transaction[] = useAppSelector(selectTransactions);
    const events: Event[] = useAppSelector(selectEvents);
    const eventHistory: EventHistory = useAppSelector(selectEventHistory);

    const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) return;
        if (files.length === 0) return;
        const file = files[0];
        const reader = new FileReader();
        reader.readAsText(file)
        reader.onload = () => {
            try {
                const json = JSON.parse(reader.result as string);
                if (json.version === '0.0.1') {
                    saveDBAsFile(users, transactions, events, eventHistory);
                    storeDispatch(setUsers(json.users));
                    storeDispatch(setTransactions(json.transactions));
                    storeDispatch(setEvents(json.events));
                    storeDispatch(setEventHistory(json.eventHistory));
                }
                else {
                    console.warn('Версия бэкапа не поддерживается для импорта');
                }
            }
            catch {
                console.warn('Не удалось распарсить содержимое файла');
            }

        }
        storeDispatch(setMenuAnchorEl(null));
    }

    return (
        <>
            <input
                className='file-input'
                type="file"
                id="import-files"
                name="files[]"
                onChange={handleFileSelect}
            />
            <label htmlFor="import-files">
                <MenuItem>
                    <ListItemIcon>
                        <FileUploadIcon fontSize="small" />
                    </ListItemIcon>
                    Импорт из файла
                </MenuItem>
            </label>
        </>
    );
}

export default Import;
