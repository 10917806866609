import React, { FC } from "react";
import ReplayIcon from '@mui/icons-material/Replay';
import { reloadWithoutCache } from "../../../utils/reloadWithoutCache";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useAppDispatch } from "../../../store/hooks";
import { setMenuAnchorEl } from "../../../store/reducers/settingsSlice";

const ReloadButton: FC = () => {
    const storeDispatch = useAppDispatch();

    const handleClick = () => {
        storeDispatch(setMenuAnchorEl(null));
        reloadWithoutCache();
    }

    return (
        <MenuItem onClick={handleClick}>
            <ListItemIcon>
                <ReplayIcon fontSize="small"/>
            </ListItemIcon>
            Очистить кэш
        </MenuItem>
    )
};

export default ReloadButton;