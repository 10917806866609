import React, { FC, useCallback, useMemo, useState } from "react";
import { TagsWrapper, Wrapper } from "./styled";
import { Event } from "../../../interfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { add, addEventHistory, selectEvents } from "../../../store/reducers/eventSlice";
import { EventTag } from "./EventTag/EventTag";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CreateEventDialog } from "../CreateEventDialog/CreateEventDialog";
import { ConfirmationDialog } from "../../ConfirmationDialog/ConfirmationDialog";
import OutlinedInput from "@mui/material/OutlinedInput";
import { getHistoryItemTitle } from "../../../utils/getHistoryItemTitle";

interface EventTagsProps {
    daySelected: Date;
}

export const EventTags: FC<EventTagsProps> = ({ daySelected }) => {
    const events: Event[] = useAppSelector(selectEvents);
    const [eventToAdd, setEventToAdd] = useState<Event | null>(null);
    const [openCreateEventDialog, setOpenCreateEventDialog] = useState<boolean>(false);
    const [openConfirmAddEvent, setOpenConfirmAddEvent] = useState<boolean>(false);
    const [count, setCount] = useState<number | undefined>(undefined);
    const storeDispatch = useAppDispatch();

    const handleAddEvent = (event: Event) => {
        storeDispatch(add(event));
    }

    const handleClickTag = (event: Event) => {
        setEventToAdd(event);
        setOpenConfirmAddEvent(true);
    };

    const handleAddEventHistory = () => {
        if (!eventToAdd) {
            return;
        }
        const dateKey = daySelected.toISOString().slice(0, 10);
        storeDispatch(addEventHistory({
            dateKey,
            eventHistoryItem: {
                id: new Date().getTime(),
                eventId: eventToAdd.id,
                count,
            },
        }))
    };

    const handleChangeCount = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCount(e.target.value === '' ? undefined : +e.target.value);
    }, [setCount]);

    const confirmMessage = `Событие "${getHistoryItemTitle(eventToAdd, undefined)}" произошло ${daySelected.toLocaleDateString()}?`;

    const countInput = useMemo(() => (
        <OutlinedInput
            placeholder='Счётчик'
            id="count"
            type='number'
            value={count}
            onChange={handleChangeCount}
            autoFocus
        />
    ), [count, handleChangeCount])

    return (
        <Wrapper>
            <IconButton onClick={() => setOpenCreateEventDialog(true)}>
                <AddIcon />
            </IconButton>
            <TagsWrapper>
                {events.map((event) => (
                    <EventTag
                        event={event}
                        onClick={handleClickTag}
                        key={event.id}
                    />
                ))}
            </TagsWrapper>
            <CreateEventDialog
                open={openCreateEventDialog}
                onClose={() => setOpenCreateEventDialog(false)}
                onEnter={handleAddEvent}
            />
            <ConfirmationDialog
                open={openConfirmAddEvent}
                onClose={() => setOpenConfirmAddEvent(false)}
                message={confirmMessage}
                onConfirm={handleAddEventHistory}
                extension={countInput}
            />
        </Wrapper>
    )
};
