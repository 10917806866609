import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Index from "./components/Index";
import { Provider } from 'react-redux';
import { store } from './store/store';

import './css/index.css';
import './css/App.css';

declare global {
    interface Window {
        cacheName: string;
    }
}

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/service-worker.js').then(function() {
            console.log('ServiceWorker registration successful');
        }, function(err) {
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}
else {
    console.log('no service worker in navigator');
}

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>
                <Index />
          </BrowserRouter>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
