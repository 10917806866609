import { getCurrentDateStr } from "./getCurrentDateStr";

const PREFIX = 'Debt';
const ONLY_TRANSACTIONS_SUFFIX = 'transactions-';
const EXTENSION = 'json';

interface GetFileNameProps {
    onlyTransactions?: boolean
}

export function getFileName({ onlyTransactions }: GetFileNameProps = {}) {
    const suffix = onlyTransactions ? ONLY_TRANSACTIONS_SUFFIX : '';

    return `${PREFIX}-${suffix}${getCurrentDateStr()}.${EXTENSION}`;
}
