import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useState } from "react";
import SearchForm from "../../History/Search/Search";
import { ICONS } from "../../../icons";
import { Icons } from "./styled";
import Pagination, { PAGE_SIZE_LIST_DEFAULT } from "../../Pagination/Pagination";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Button } from "@mui/material";
import { Event } from "../../../interfaces";

export interface Props {
    open: boolean
    onClose: () => void
    onEnter: (event: Event) => void
}

export const CreateEventDialog = ({ onClose, open, onEnter }: Props) => {
    const [searchIconText, setSearchIconText] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [iconSelected, setIconSelected] = useState<string>();
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE_LIST_DEFAULT[1]);

    const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const handleClickIcon = (iconName: string) => {
        if (iconName === iconSelected) {
            setIconSelected(undefined);
        } else {
            setIconSelected(iconName);
        }
    }

    const handleSave = () => {
        if (title.replace(/ /g, '') === '' && !iconSelected) {
            alert('Введите наименование и/или выберите иконку');
            return;
        }
        onEnter({
            id: (new Date()).getTime(),
            title: title || undefined,
            icon: iconSelected,
        });
        onClose();
    }

    const handleEnter = (e: React.KeyboardEvent) => {
        if (e.key !== 'Enter')
            return;
        e.preventDefault();
        handleSave();
    }

    const iconNamesFiltered = Object.keys(ICONS).filter((iconName) => iconName.toLowerCase().includes(searchIconText.toLowerCase()));

    return (
        <Dialog onClose={onClose} open={open}>
            <OutlinedInput
                placeholder='Наименование'
                id="title"
                type='text'
                value={title}
                onChange={handleChangeTitle}
                onKeyDown={handleEnter}
            />
            <SearchForm value={searchIconText} onChange={setSearchIconText} placeholder='Поиск иконки (Eng)' />
            <Icons>
            {iconNamesFiltered.slice(page * pageSize, (page + 1) * pageSize).map((iconName) => {
                const Icon = ICONS[iconName as keyof typeof ICONS];

                return Icon
                    ? (
                        <Icon
                            onClick={() => handleClickIcon(iconName)}
                            style={{
                                background: iconName === iconSelected ? 'lightgreen' : 'initial'
                            }}
                            key={iconName}
                        />
                    )
                    : null;
            })}
            </Icons>
            {iconNamesFiltered.length > pageSize && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    recordsCount={iconNamesFiltered.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            )}
            <Button onClick={handleSave}>
                Добавить
            </Button>
        </Dialog>
    );
};
