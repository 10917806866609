import React, { useState } from "react";
import { Calendar } from "./Calendar/Calendar";
import { EventTags } from "./EventTags/EventTags";
import { DayEvents } from "./DayEvents/DayEvents";

export const Events = () => {
    const dateSelectedDefault = new Date();
    dateSelectedDefault.setHours(0, 0, 0, 0);
    const [daySelected, setDaySelected] = useState<Date>(dateSelectedDefault);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            height: '100%',
        }}>
            <Calendar daySelected={daySelected} setDaySelected={setDaySelected} />
            <DayEvents date={daySelected} />
            <EventTags daySelected={daySelected} />
        </div>
    )
}
