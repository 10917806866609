import { Chip, styled } from "@mui/material";

export const Wrapper = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&>div:last-child': {
        flex: 1,
        overflowX: 'auto',
    }
});

export const TagsWrapper = styled('div')({
    overflow: 'auto',
    whiteSpace: 'nowrap'
})

export const Tag = styled(Chip)({
    margin: '0 0.2rem',
})
