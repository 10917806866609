import { CSSProperties, FC } from 'react'
import { XYCoord, useDragLayer } from 'react-dnd'
import { ItemTypes } from '../ItemTypes'
import { BoxPreviewMove } from './BoxPreviewMove/BoxPreviewMove'
import { BoxPreviewTransaction } from "./BoxPreviewTransaction/BoxPreviewTransaction";

const layerStyles: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
}

function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        }
    }

    let { x, y } = currentOffset

    const transform = `translate(${x}px, ${y}px)`
    return {
        transform,
        WebkitTransform: transform,
    }
}

export const DragLayer: FC = () => {
    const { itemType, isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }))

    function renderItem() {
        switch (itemType) {
            case ItemTypes.BOX_MOVE:
                return <BoxPreviewMove userId={item.id} />
            case ItemTypes.BOX_TRANS:
                return <BoxPreviewTransaction userId={item.id} />
            default:
                return null
        }
    }

    if (!isDragging) {
        return null
    }

    return (
        <div style={layerStyles}>
            <div
                style={getItemStyles(initialOffset, currentOffset)}
            >
                {renderItem()}
            </div>
        </div>
    )
}
