import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import * as React from "react";
import { FC, ReactNode, useCallback } from "react";

interface ConfirmationDialogProps {
    open: boolean;
    onClose: () => void;
    message: string;
    onConfirm: () => void;
    onCancel?: () => void;
    extension?: ReactNode;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
    open,
    onClose,
    message,
    onConfirm,
    onCancel= () => undefined,
    extension,
}) => {

    const handleCancel = useCallback(() => {
        onClose();
        onCancel();
    }, [onClose, onCancel]);

    const handleConfirm = useCallback(() => {
        onClose();
        onConfirm();
    }, [onClose, onConfirm]);

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>{message}</DialogTitle>
            {!!extension && (
                <DialogContent>
                    {extension}
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={handleCancel}>Отменить</Button>
                <Button
                    onClick={handleConfirm}
                    variant='contained'
                    autoFocus
                >
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    )
}
