import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Event, EventHistory, EventHistoryItem } from "../../interfaces";

export interface UserState {
    events: Event[]
    eventHistory: EventHistory
}

const initialState: UserState = {
    events: [],
    eventHistory: {},
};

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<Event[]>) => {
            state.events = action.payload;
        },
        add: (state, action: PayloadAction<Event>) => {
            state.events.push(action.payload);
        },
        removeEvent: (state, action: PayloadAction<number>) => {
            const i = state.events.findIndex((event) => event.id === action.payload);
            if (i >= 0) {
                state.events.splice(i, 1);
            }
            Object.keys(state.eventHistory).forEach((dateKey) => {
                state.eventHistory[dateKey] = state.eventHistory[dateKey].filter((item) => item.eventId !== action.payload);
            })
        },
        setEventHistory: (state, action: PayloadAction<EventHistory>) => {
            state.eventHistory = action.payload;
        },
        addEventHistory: (state, action: PayloadAction<{ dateKey: string, eventHistoryItem: EventHistoryItem }>) => {
            const currentIds = state.eventHistory[action.payload.dateKey];
            if (currentIds) {
                if (!currentIds.includes(action.payload.eventHistoryItem)) {
                    state.eventHistory[action.payload.dateKey].push(action.payload.eventHistoryItem);
                }
            } else {
                state.eventHistory[action.payload.dateKey] = [action.payload.eventHistoryItem];
            }
        },
        removeEventHistory: (state, action: PayloadAction<{ dateKey: string, id: number }>) => {
            if (!state.eventHistory[action.payload.dateKey]) {
                return;
            }
            const indexToRemove = state.eventHistory[action.payload.dateKey].findIndex((item) => item.id === action.payload.id);
            if (indexToRemove < 0) {
                return;
            }
            state.eventHistory[action.payload.dateKey].splice(indexToRemove, 1);
        },
    },
});

export const {  set, add, removeEvent, setEventHistory, addEventHistory, removeEventHistory } = eventSlice.actions;

export const selectEvents = (state: RootState): Event[] => state.event.events
export const selectEventHistory = (state: RootState): EventHistory => state.event.eventHistory

export default eventSlice.reducer;
