import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDrag, DragSourceMonitor, useDrop } from 'react-dnd'
import { ItemTypes } from '../../ItemTypes'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { UserBox } from '../../UserBox/UserBox'
import { useAppDispatch } from "../../../../store/hooks";
import { add } from '../../../../store/reducers/transactionSlice';
import { Transaction, User } from "../../../../interfaces";
import TransactionDialog from "./TransactionDialog/TransactionDialog";
import { getCanDrop } from "./helpers";
import * as React from "react";
import { USER_ME, USER_SHOP } from "../../../../constants";
import ContextMenu from "./ContextMenu/ContextMenu";

function getStyles(
    left: number,
    top: number,
): CSSProperties {
    const transform = `translate3d(${left}px, ${top}px, 0)`
    return {
        position: 'absolute',
        transform,
        WebkitTransform: transform,
        // IE fallback: hide the real node using CSS when dragging
        // because IE will ignore our custom "empty image" drag preview.
        // opacity: isDragging ? 0 : 1,
        // height: isDragging ? 0 : '',
    }
}

export interface DraggableBoxProps {
    user: User
}

export const DraggableBox: FC<DraggableBoxProps> = ({ user }) => {
    const ref = useRef<HTMLDivElement>(null);
    const storeDispatch = useAppDispatch();
    const [openTransactionDialog, setOpenTransactionDialog] = useState<boolean>(false);
    const [borrowerId, setBorrowerId] = useState<number | null>(null);

    const isMe: boolean = useMemo(() => (
        user.id === USER_ME.id
    ), [user.id]);

    const handleClick: (() => void) | undefined = useMemo(() => {
        if (isMe) {
            return () => {
                handleOpenTransationDialog();
                setBorrowerId(USER_SHOP.id);
            };
        }
        return undefined;
    }, [isMe]);

    const handleOpenTransationDialog = () => {
        setOpenTransactionDialog(true);
    }

    const handleCloseTransationDialog = () => {
        setOpenTransactionDialog(false);
    }

    const handleEnter = useCallback((value: number, comment?: string, categoryId?: number) => {
        if (borrowerId === null)
            return alert('Ошибка borrowerId');
        const transaction: Transaction = {
            id: Math.random(),
            creditorId: user.id,
            borrowerId: borrowerId,
            value,
            comment,
            date: (new Date()).toISOString(),
            categoryId
        }
        storeDispatch(add(transaction));
    }, [borrowerId, user.id, storeDispatch]);

    const [, drag, preview] = useDrag(() => ({
        type: ItemTypes.BOX_TRANS,
        item: user,
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as User
            if (item && dropResult) {
                let alertMessage = ''
                alertMessage = `Transaction '${user.id}' into '${dropResult.id}'`
                console.log(alertMessage);
                handleOpenTransationDialog();
                setBorrowerId(dropResult.id);
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }),
    [user]);

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: ItemTypes.BOX_TRANS,
        canDrop(item) {
            return getCanDrop(user, item as User)
        },
        drop: (): User => (user),
        collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }),
    []);

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    drag(drop(ref))

    const isActive = canDrop && isOver

    return (
        <div
            ref={ref}
            style={getStyles(user.left, user.top)}
        >
            <div className='userBox__contextMenuWrapper'>
                <UserBox
                    userId={user.id}
                    isActive={isActive}
                    canDrop={canDrop}
                    onClick={handleClick}
                />

                <TransactionDialog
                    open={openTransactionDialog}
                    onClose={handleCloseTransationDialog}
                    onEnter={handleEnter}
                />

                {!isMe && (
                    <ContextMenu userId={user.id} />
                )}
            </div>
        </div>
    )
}
