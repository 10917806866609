import React, { FC, useState } from "react";
import { Event } from "../../../../interfaces";
import { ConfirmationDialog } from "../../../ConfirmationDialog/ConfirmationDialog";
import { useLongPress } from "../../../../hooks/useLongPress";
import { removeEventHistory } from "../../../../store/reducers/eventSlice";
import { useAppDispatch } from "../../../../store/hooks";
import { Preview } from "../../Calendar/Cell/Preview/Preview";
import { getHistoryItemTitle } from "../../../../utils/getHistoryItemTitle";

interface EventRowProps {
    id: number;
    event: Event;
    dateKey: string;
    count?: number;
}

export const EventRow: FC<EventRowProps> = ({ id, event, dateKey, count }) => {
    const [openConfirmRemoving, setOpenConfirmRemoving] = useState<boolean>(false);
    const storeDispatch = useAppDispatch();

    const handleLongPress = () => {
        setOpenConfirmRemoving(true);
    }

    const longPressHandlers = useLongPress({
        onLongPress: handleLongPress
    });

    const handleRemove = () => {
        storeDispatch(removeEventHistory({ dateKey, id }));
    }

    return (
        <>
        <div
            {...longPressHandlers}
            style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'}}
        >
            <div><Preview event={event} /></div>
            <div>{getHistoryItemTitle(event, count)}</div>
        </div>
        <ConfirmationDialog
            open={openConfirmRemoving}
            onClose={() => setOpenConfirmRemoving(false)}
            message={`Отменить "${getHistoryItemTitle(event, count)}"?`}
            onConfirm={handleRemove}
        />
        </>
    )
}
