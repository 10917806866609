import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {GitSettings, selectGitSettings, setGit} from "../../../../store/reducers/settingsSlice";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {DialogTitle, TextField} from "@mui/material";

export interface Props {
    open: boolean
    onClose: () => void
}

const GitSettingsDialog = ({ onClose, open }: Props) => {
    const gitSettings: GitSettings | undefined = useAppSelector(selectGitSettings);
    const storeDispatch = useAppDispatch();

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Git</DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 10 }}>
                    <TextField
                        label="Owner"
                        value={gitSettings.owner}
                        onChange={(e) => storeDispatch(setGit({
                            ...gitSettings,
                            owner: e.target.value || '',
                        }))}
                    />
                    <TextField
                        label="Repo"
                        value={gitSettings.repo}
                        onChange={(e) => storeDispatch(setGit({
                            ...gitSettings,
                            repo: e.target.value || '',
                        }))}
                    />
                    <TextField
                        label="Token"
                        value={gitSettings.token}
                        onChange={(e) => storeDispatch(setGit({
                            ...gitSettings,
                            token: e.target.value || '',
                        }))}
                    />
                    <TextField
                        label="Id"
                        value={gitSettings.id}
                        onChange={(e) => storeDispatch(setGit({
                            ...gitSettings,
                            id: e.target.value || '',
                        }))}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default GitSettingsDialog;
