import {Event, EventHistory, Transaction, User} from "../interfaces";
import {VERSION} from "../constants";

export const getProjectDataJsonString = (users: User[], transactions: Transaction[], events: Event[], eventHistory: EventHistory) => {
    return JSON.stringify({
        version: VERSION,
        users,
        transactions,
        events,
        eventHistory,
    });
};