import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const Month = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
});

export const ButtonChangeMonth = styled(Button)({
    padding: '0.5rem 1rem'
});
