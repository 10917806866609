import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    InputBase,
    MenuItem, Select, SelectChangeEvent
} from "@mui/material";
import * as React from "react";
import {ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useState} from "react";
import FilterIcon from "@mui/icons-material/FilterAlt";
import {User} from "../../../interfaces";
import {useAppSelector} from "../../../store/hooks";
import {selectUsers} from "../../../store/reducers/userSlice";
import {USER_ME} from "../../../constants";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";

interface FilterDialogProps {
    userIdFilter: number | undefined;
    setUserIdFilter: Dispatch<SetStateAction<number | undefined>>;
    valueFromFilter: number | undefined;
    setValueFromFilter: Dispatch<SetStateAction<number | undefined>>;
    valueToFilter: number | undefined;
    setValueToFilter: Dispatch<SetStateAction<number | undefined>>;
}

export const FilterDialog: FC<FilterDialogProps> = ({
    userIdFilter,
    setUserIdFilter,
    valueFromFilter,
    setValueFromFilter,
    valueToFilter,
    setValueToFilter,
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const users: User[] = useAppSelector(selectUsers);

    const handleSelect = useCallback((e: SelectChangeEvent<number>) => {
        if (e.target.value === 0) {
            setUserIdFilter(undefined);
        }
        setUserIdFilter(Number(e.target.value));
        setOpen(false);
    }, [setUserIdFilter]);

    const handleChangeValueFrom = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '' || e.target.value === '0') {
            setValueFromFilter(undefined);
        }
        setValueFromFilter(Number(e.target.value));
    }, [setValueFromFilter]);

    const handleChangeValueTo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '' || e.target.value === '0') {
            setValueToFilter(undefined);
        }
        setValueToFilter(Number(e.target.value));
    }, [setValueToFilter]);

    return (
        <>
        <IconButton onClick={() => setOpen(true)}>
            <FilterIcon />
        </IconButton>
        <Dialog onClose={() => setOpen(false)} open={open}>
            <DialogTitle>Фильтры</DialogTitle>
            <DialogContent>
                <p>Пользователь:</p>
                <Select
                    value={userIdFilter || 0}
                    onChange={handleSelect}
                    variant='outlined'
                    input={<InputBase />}
                    style={{width: '100%'}}
                >
                    <MenuItem value={0}>
                        Не выбран
                    </MenuItem>
                    {users.filter((user) => user.id !== USER_ME.id).map((user) => (
                        <MenuItem value={user.id} key={user.id}>
                            {user.name}
                        </MenuItem>
                    ))}
                </Select>

                <p>Сумма:</p>
                <div>
                    <OutlinedInput
                        placeholder='От'
                        type='number'
                        value={valueFromFilter}
                        onChange={handleChangeValueFrom}
                        autoFocus
                    />
                    <OutlinedInput
                        placeholder='До'
                        type='number'
                        value={valueToFilter}
                        onChange={handleChangeValueTo}
                        autoFocus
                    />
                </div>
                <Button
                    onClick={() => setOpen(false)}
                    style={{ width: '100%' }}
                >
                    Показать
                </Button>
            </DialogContent>
        </Dialog>
        </>
    )
}
