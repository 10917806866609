import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";

export interface Props {
    open: boolean
    onClose: () => void
    onEnter: (name: string) => void
}

const RenameDialog = ({ onClose, open, onEnter }: Props) => {
    const [name, setName] = useState<string>('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }

    const handleSave = () => {
        onEnter(name);
        onClose();
    }

    const handleEnter = (e: React.KeyboardEvent) => {
        if (e.key !== 'Enter')
            return;
        e.preventDefault();
        handleSave();
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <OutlinedInput
                placeholder='Имя'
                id="outlined-adornment-password"
                type='text'
                value={name}
                onChange={handleChange}
                onKeyDown={handleEnter}
                autoFocus
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleSave}
                        >
                            <CheckIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </Dialog>
    );
}

export default RenameDialog;
