import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Transaction } from "../../interfaces";
import { RECENT_TRANSACTION_VIEW_TIME, USER_ME, USER_SHOP } from "../../constants";

export interface TransactionState {
    transactions: Transaction[]
    recentTransactions: Transaction[]
}

const initialState: TransactionState = {
    transactions: [],
    recentTransactions: []
};

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<Transaction[]>) => {
            state.transactions = action.payload;
        },
        add: (state, action: PayloadAction<Transaction>) => {
            state.transactions.push(action.payload);
            state.recentTransactions = getRecentTransactions(state.transactions)
        },
        remove: (state, action: PayloadAction<number>) => {
            state.transactions = state.transactions.filter((transaction) => transaction.id !== action.payload);
        },
        update: (state, action: PayloadAction<Transaction>) => {
            state.transactions = state.transactions.map((transaction) => {
                if (transaction.id === action.payload.id)
                    return action.payload;
                return transaction;
            });
        },
        updateRecent: (state) => {
            state.recentTransactions = getRecentTransactions(state.transactions)
        },
    },
});

export const { set, add, remove, update, updateRecent } = transactionSlice.actions;

export const selectTransactions = (state: RootState, filters?: { comment?: string, userId?: number, valueFrom?: number, valueTo?: number }) => {
    if (!filters) {
        return state.transaction.transactions;
    }
    return state.transaction.transactions.filter((transaction) => {
        const comment = transaction.comment?.toLocaleLowerCase();
        if (filters.comment && (!comment || !comment.includes(filters.comment.toLowerCase()))) {
            return false;
        }
        if (filters.userId !== undefined && filters.userId !== transaction.creditorId && filters.userId !== transaction.borrowerId) {
            return false;
        }
        if (filters.valueFrom !== undefined && filters.valueFrom !== 0 && transaction.value < filters.valueFrom) {
            return false;
        }
        if (filters.valueTo !== undefined && filters.valueTo !== 0 && transaction.value > filters.valueTo) {
            return false;
        }
        return true;
    });
};
export const selectOnlyCategorizedMinimalTransactions = (state: RootState) => (
    state.transaction.transactions
        .filter((transaction) => transaction.categoryId !== undefined)
        .map(({ id, value, categoryId, date }) => ({
            id,
            value,
            categoryId,
            date,
            creditorId: USER_ME.id,
            borrowerId: USER_SHOP.id,
        }))
);
export const selectRecentTransactions = (state: RootState) => state.transaction.recentTransactions;
export const selectTransaction = (state: RootState, id: number): Transaction | undefined => (
    state.transaction.transactions.find((transaction) => (
        transaction.id === id
    ))
);
export const selectUserTransactionsSum = (state: RootState, userId: number): number => {
    const userIndex: number = state.user.users.findIndex((user) => user.id === userId);
    if (userIndex < 0)
        return 0;
    let sum = 0;
    state.transaction.transactions.forEach((transaction) => {
        if (userId === transaction.creditorId) {
            sum += transaction.value;
        }
        else if (userId === transaction.borrowerId) {
            sum -= transaction.value;
        }
    });
    return sum;
}

export default transactionSlice.reducer;

const getRecentTransactions = (transactions: Transaction[]): Transaction[] => {
    return transactions.filter((transaction: Transaction): boolean => {
        if (!transaction.date)
            return false;
        return (new Date()).getTime() - new Date(transaction.date).getTime() < RECENT_TRANSACTION_VIEW_TIME;
    })
};
