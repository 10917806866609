import * as React from "react";
import { ChangeEvent, FC } from "react";
import { selectUsers } from "../../store/reducers/userSlice";
import { selectTransactions, set as setTransactions } from "../../store/reducers/transactionSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Event, EventHistory, Transaction, User } from "../../interfaces";
import { saveDBAsFile } from "../Export/Export";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import { setMenuAnchorEl } from "../../store/reducers/settingsSlice";
import { selectEventHistory, selectEvents } from "../../store/reducers/eventSlice";

const ImportAdditional: FC = () => {
    const storeDispatch = useAppDispatch();
    const users: User[] = useAppSelector(selectUsers);
    const transactions: Transaction[] = useAppSelector(selectTransactions);
    const events: Event[] = useAppSelector(selectEvents);
    const eventHistory: EventHistory = useAppSelector(selectEventHistory);

    const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) return;
        if (files.length === 0) return;
        const file = files[0];
        const reader = new FileReader();
        reader.readAsText(file)
        reader.onload = () => {
            try {
                const json = JSON.parse(reader.result as string);
                if (json.version === '0.0.1') {
                    saveDBAsFile(users, transactions, events, eventHistory);
                    storeDispatch(setTransactions(
                        ([
                            ...json.transactions,
                            ...transactions
                        ] as Transaction[]).sort((a, b) => {
                            if (!a.date || !b.date) {
                                return 0;
                            }
                            return a.date.localeCompare(b.date);
                        })
                    ));
                }
                else {
                    console.warn('Версия бэкапа не поддерживается для импорта');
                }
            }
            catch {
                console.warn('Не удалось распарсить содержимое файла');
            }

        }
        storeDispatch(setMenuAnchorEl(null));
    }

    return (
        <>
            <input
                className='file-input'
                type="file"
                id="import-additional-files"
                name="files[]"
                onChange={handleFileSelect}
            />
            <label htmlFor="import-additional-files">
                <MenuItem style={{ whiteSpace: 'normal' }}>
                    <ListItemIcon>
                        <FileUploadIcon fontSize="small" />
                    </ListItemIcon>
                    Импорт из файла вдобавок к текущему
                </MenuItem>
            </label>
        </>
    );
}

export default ImportAdditional;
