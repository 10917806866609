import React, { FC } from "react";
import ReactSimpleKeyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

interface IProps {
    onChange: (input: string) => void;
}

export const Keyboard: FC<IProps> = ({
    onChange,
}) => (
    <ReactSimpleKeyboard
        layout={{
            default: ["1 2 3", "4 5 6", "7 8 9", " 0 ."],
        }}
        onKeyPress={onChange}
        theme="hg-theme-default hg-layout-numeric numeric-theme"
    />
);
