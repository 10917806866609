import React, { FC } from "react";
import { DivPreview } from './styled';
import { Event } from "../../../../../interfaces";
import { ICONS } from "../../../../../icons";

interface PreviewProps {
    event: Event;
}

export const Preview: FC<PreviewProps> = ({ event }) => {
    if (event.icon && ICONS[event.icon as keyof typeof ICONS]) {
        const Icon = ICONS[event.icon as keyof typeof ICONS];

        return (
            <DivPreview>
                <Icon />
            </DivPreview>
        )
    }

    if (!event.title) {
        return null;
    }

    return (
        <DivPreview>
            {`${event.title[0].toUpperCase()}${event.title[1] || ''}`}
        </DivPreview>
    )
}
