import React, { Dispatch, FC, SetStateAction, useMemo } from "react";
import { ButtonChangeMonth, Month } from "./styled";
import { MONTHS } from "../Events/Calendar/constants";
import { setNextMonth, setPreviousMonth } from "../Events/Calendar/helpers";

interface MonthPeriodSelectorProps {
    type: 'month';
    year: number;
    setYear: Dispatch<SetStateAction<number>>;
    month: number;
    setMonth: Dispatch<SetStateAction<number>>;
}

interface YearPeriodSelectorProps {
    type: 'year';
    year: number;
    setYear: Dispatch<SetStateAction<number>>;
    month?: number;
    setMonth?: Dispatch<SetStateAction<number>>;
}

export const PeriodSelector: FC<MonthPeriodSelectorProps | YearPeriodSelectorProps> = ({ type, month, year, setMonth, setYear }) => {
    const currentYear = useMemo(() => (new Date()).getFullYear(), []);

    const label = type === 'month' ? `${MONTHS[month]}${year !== currentYear ? ` ${year}` : ''}` : year.toString();

    const previous = () => {
        if (type === 'month') {
            setPreviousMonth(month, setMonth, setYear);
            return;
        }
        setYear((year) => year - 1);
    };

    const next = () => {
        if (type === 'month') {
            setNextMonth(month, setMonth, setYear);
            return;
        }
        setYear((year) => year + 1);
    };

    return(
        <Month>
            <ButtonChangeMonth onClick={previous}>{'<'}</ButtonChangeMonth>
                {label}
            <ButtonChangeMonth onClick={next}>{'>'}</ButtonChangeMonth>
        </Month>
    )
};
