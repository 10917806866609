import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface GitSettings {
    id: string;
    owner: string;
    repo: string;
    token: string;
}

export interface SettingsState {
    moveMode: boolean;
    lastDBAutoExport?: string; // Date in ISO
    menuAnchorEl: HTMLElement | null;
    git: GitSettings;
}

const initialState: SettingsState = {
    moveMode: false,
    menuAnchorEl: null,
    git: {
        owner: '',
        repo: '',
        token: '',
        id: '',
    },
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        toggleMoveMode: (state) => {
            state.moveMode = !state.moveMode;
        },
        setMoveMode:  (state, action: PayloadAction<boolean>) => {
            state.moveMode = action.payload;
        },
        setLastDBAutoExport:  (state, action: PayloadAction<string | undefined>) => {
            state.lastDBAutoExport = action.payload;
        },
        setMenuAnchorEl: (state, action: PayloadAction<HTMLElement | null>) => {
            // @ts-ignore
            state.menuAnchorEl = action.payload;
        },
        setGit:  (state, action: PayloadAction<GitSettings>) => {
            state.git = action.payload;
        },
    },
});

export const {
    toggleMoveMode,
    setMoveMode,
    setLastDBAutoExport,
    setMenuAnchorEl,
    setGit,
} = settingsSlice.actions;

export const selectMoveMode = (state: RootState) => state.settings.moveMode;
export const selectLastDBAutoExport = (state: RootState) => state.settings.lastDBAutoExport;
export const selectGitSettings = (state: RootState) => state.settings.git;
export const selectIsMenuOpen = (state: RootState) => state.settings.menuAnchorEl;

export default settingsSlice.reducer;
