import { getIcon } from "../helpers";
import { Tag } from "../styled";
import React, { FC, useState } from "react";
import { useLongPress } from "../../../../hooks/useLongPress";
import { Event } from "../../../../interfaces";
import { useAppDispatch } from "../../../../store/hooks";
import { removeEvent } from "../../../../store/reducers/eventSlice";
import { ConfirmationDialog } from "../../../ConfirmationDialog/ConfirmationDialog";

interface EventTagProps {
    event: Event;
    onClick: (event: Event) => void
}

export const EventTag: FC<EventTagProps> = ({ event, onClick }) => {
    const [openConfirmRemoving, setOpenConfirmRemoving] = useState<boolean>(false);
    const storeDispatch = useAppDispatch();

    const handleLongPress = () => {
        setOpenConfirmRemoving(true);
    }

    const handleRemove = () => {
        storeDispatch(removeEvent(event.id));
    }

    const longPressHandlers = useLongPress({
        onClick: () => onClick(event),
        onLongPress: handleLongPress
    });

    return (
        <>
        <Tag
            {...longPressHandlers}
            icon={getIcon(event)}
            label={event.title}
            color="primary"
        />
        <ConfirmationDialog
            open={openConfirmRemoving}
            onClose={() => setOpenConfirmRemoving(false)}
            message={`Удалить ${event.title ? `"${event.title}"` : 'событие'} и все его упоминания?`}
            onConfirm={handleRemove}
        />
        </>
    )
};
