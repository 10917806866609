import React, { useMemo, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { selectTransactions } from "../../store/reducers/transactionSlice";
import TransactionRow from "./TransactionRow/TransactionRow";
import { Transaction } from "../../interfaces";
import Pagination, { PAGE_SIZE_LIST_DEFAULT } from "../Pagination/Pagination";
import Search from "./Search/Search";
import {FilterDialog} from "./FilterDialog/FilterDialog";

const History = () => {
    const [searchText, setSearchText] = useState<string>('');
    const [userIdFilter, setUserIdFilter] = useState<number | undefined>();
    const [valueFromFilter, setValueFromFilter] = useState<number | undefined>();
    const [valueToFilter, setValueToFilter] = useState<number | undefined>();
    const transactions: Transaction[] = useAppSelector((state) => selectTransactions(state, { comment: searchText, userId: userIdFilter, valueFrom: valueFromFilter, valueTo: valueToFilter }));
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE_LIST_DEFAULT[2]);

    const transactionsPerPage: Transaction[] = useMemo(() => {
        const end: number = transactions.length - (page * pageSize);
        const begin: number = end - pageSize < 0 ? 0 : end - pageSize;
        return transactions.slice(begin, end);
    }, [transactions, page, pageSize]);

    const transactionsReversed: Transaction[] = useMemo(() => (
        [...transactionsPerPage].reverse()
    ), [transactionsPerPage]);

    return (
        <div className='historyPage'>
            <div className='historySearch'>
                <Search value={searchText} onChange={setSearchText} />
                <FilterDialog
                    userIdFilter={userIdFilter}
                    setUserIdFilter={setUserIdFilter}
                    valueFromFilter={valueFromFilter}
                    setValueFromFilter={setValueFromFilter}
                    valueToFilter={valueToFilter}
                    setValueToFilter={setValueToFilter}
                />
            </div>
            <div className='historyList'>
                {transactionsReversed.map((transaction) => (
                    <TransactionRow
                        id={transaction.id}
                        key={transaction.id}
                    />
                ))}
            </div>
            <div className='historyPagination'>
                <Pagination
                    page={page}
                    setPage={setPage}
                    recordsCount={transactions.length}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                />
            </div>
        </div>
    )
}

export default History;
