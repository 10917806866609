import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import * as React from "react";
import { FC } from "react";

interface SaveButtonProps {
    isLoading: boolean;
    needToSave: boolean;
    onClick: () => void;
}

export const SaveButton: FC<SaveButtonProps> = ({ isLoading, needToSave, onClick }) => {
    if (isLoading) {
        return <CircularProgress size='1rem' color='inherit'/>;
    }

    if (needToSave) {
        return (
            <Button
                onClick={onClick}
                startIcon={<SaveIcon color='secondary'/>}
            >
                Не сохранено
            </Button>
        );
    }

    return <div />;
}
