import React, { Dispatch, useCallback, useMemo, FocusEvent } from "react";
import { IconButton, MenuItem, Select, TextField, InputBase, SelectChangeEvent } from "@mui/material";
import {
    SkipPrevious,
    SkipNext,
    NavigateBefore,
    NavigateNext,
} from "@mui/icons-material";

export const PAGE_SIZE_LIST_DEFAULT: number[] = [1, 25, 50, 100, 250];

interface PaginationProps {
    page: number
    setPage: Dispatch<number>
    recordsCount: number
    pageSize?: number
    setPageSize?: Dispatch<number>
    pageSizeList?: number[]
    disabled?: boolean
    className?: string
}

const Pagination = ({
    page,
    setPage,
    recordsCount,
    pageSizeList = PAGE_SIZE_LIST_DEFAULT,
    pageSize = pageSizeList ? pageSizeList[0] : PAGE_SIZE_LIST_DEFAULT[0],
    setPageSize,
    disabled = false,
    className,
}: PaginationProps) => {
    const pageToView = useMemo(() => page + 1, [page]);
    const pageCount = useMemo(() => Math.ceil(recordsCount / pageSize) || 1, [recordsCount, pageSize]);
    const canPreviousPage = useMemo(() => page > 0, [page]);
    const canNextPage = useMemo(() => page < pageCount - 1, [page, pageCount]);

    const handlePreviousPage = useCallback(() => {
        setPage(page - 1);
    }, [page, setPage]);

    const handleNextPage = useCallback(() => {
        setPage(page + 1);
    }, [page, setPage]);

    const handleFirstPage = useCallback(() => {
        setPage(0);
    }, [setPage]);

    const handleLastPage = useCallback(() => {
        setPage(pageCount - 1);
    }, [pageCount, setPage]);

    const handleBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        if (value < 0 || value > pageCount) {
            return;
        }
        setPage(value ? value - 1 : 0);
    }, [pageCount, setPage]);

    const handleSelect = useCallback((e: SelectChangeEvent<number>) => {
        if (setPageSize) {
            setPageSize(Number(e.target.value));
            handleFirstPage();
        }
    }, [setPageSize, handleFirstPage]);

    return (
        <div className={className}>
            <IconButton onClick={handleFirstPage} disabled={!canPreviousPage}>
                <SkipPrevious />
            </IconButton>

            <IconButton onClick={handlePreviousPage} disabled={!canPreviousPage}>
                <NavigateBefore />
            </IconButton>

            {pageToView} из {pageCount}

            <IconButton onClick={handleNextPage} disabled={!canNextPage}>
                <NavigateNext />
            </IconButton>

            <IconButton onClick={handleLastPage} disabled={!canNextPage}>
                <SkipNext />
            </IconButton>

            <TextField
                type='number'
                defaultValue={pageToView}
                onBlur={handleBlur}
                disabled={disabled || pageCount === 1}
                size='small'
                style={{ width: '2em' }}
            />

            {!!pageSize && !!setPageSize && (
                <Select
                    value={pageSize}
                    onChange={handleSelect}
                    disabled={disabled}
                    variant='outlined'
                    input={<InputBase />}
                >
                    {pageSizeList.map((pageSize) => (
                        <MenuItem value={pageSize} key={pageSize}>
                            По {pageSize}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </div>
    );
}

export default Pagination;
