import React, { FC, useCallback, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { add } from '../../../store/reducers/userSlice'
import { setMenuAnchorEl, setMoveMode } from '../../../store/reducers/settingsSlice'
import { User } from "../../../interfaces";
import RenameDialog from "./RenameDialog/RenameDialog";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import MenuItem from "@mui/material/MenuItem";

const AddUserButton: FC = () => {
    const storeDispatch = useAppDispatch();
    const [openRenameDialog, setOpenRenameDialog] = useState<boolean>(false);

    const handleCloseRenameDialog = () => {
        setOpenRenameDialog(false);
        storeDispatch(setMenuAnchorEl(null));
    }

    const handleEnter = useCallback((name: string) => {
        const newUser: User = {
            id: Math.random(),
            name,
            top: 0,
            left: 0
        }
        storeDispatch(add(newUser));
        storeDispatch(setMoveMode(true));
        storeDispatch(setMenuAnchorEl(null));
    }, [storeDispatch]);

    return (
        <>
            <MenuItem onClick={() => setOpenRenameDialog(true)}>
                <ListItemIcon>
                    <PersonAdd fontSize="small" />
                </ListItemIcon>
                Добавить карточку
            </MenuItem>
            <RenameDialog
                open={openRenameDialog}
                onClose={handleCloseRenameDialog}
                onEnter={handleEnter}
            />
        </>
    )
}

export default AddUserButton;