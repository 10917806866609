import { ICONS } from "../../../icons";
import { Event } from "../../../interfaces";
import { createElement, ReactElement } from "react";

export const getIcon = (event: Event): ReactElement | undefined => {
    const Icon = event.icon ? ICONS[event.icon as keyof typeof ICONS] : null;
    if (!Icon) {
        return undefined;
    }

    return createElement(Icon);
}
