import { useCallback, useEffect, useState } from "react";
import { createStore, get, set } from "idb-keyval";
import { VERSION } from "../constants";

const db = createStore('appData', VERSION);

export const getFromDB = async <T extends any>(key: string, onGet: (value: T) => unknown, defaultValue: T) => {
    const value: T | undefined = await get(key, db);
    if (value === undefined) {
        onGet(defaultValue);
    } else {
        onGet(value);
    }
}

export const useSaveToDB = <T extends any>(
    key: string,
    data: T,
    isFirstRender: boolean,
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaved, setIsSaved] = useState<boolean>(true);

    const saveToDB = useCallback(async ( value: T) => {
        setIsLoading(true);
        setIsSaved(false);
        try {
            await set(key, value, db);
            setIsSaved(true);
        } catch (e) {
            alert(e);
        }
        setIsLoading(false);
    }, [setIsLoading, setIsSaved, key]);

    useEffect(() => {
        if (!isFirstRender) {
            saveToDB(data);
        }
    }, [data, isFirstRender, saveToDB]);

    return { isLoading, isSaved, saveToDB };
};
