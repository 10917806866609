import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Transaction } from "../../../../../interfaces";
import {
    RECENT_TRANSACTION_UPDATE_TIME,
    RECENT_TRANSACTION_VIEW_TIME,
    USER_ME,
    USER_SHOP
} from "../../../../../constants";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { selectUserName } from "../../../../../store/reducers/userSlice";
import { updateRecent } from "../../../../../store/reducers/transactionSlice";
import { useEffect, useMemo, useRef } from "react";
import { currencyFormat } from "../../../../../utils/currencyFormat";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
});

interface Props {
    transaction: Transaction
}

const RecentTransactionSnackbar = ({ transaction }: Props) => {
    const storeDispatch = useAppDispatch();
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const userId: number = transaction.creditorId !== USER_ME.id ? transaction.creditorId : transaction.borrowerId;
    const name: string = useAppSelector((state) => selectUserName(state, userId));
    const [open, setOpen] = React.useState(true);

    const message: string = useMemo(() => {
        const isPurchase: boolean = transaction.borrowerId === USER_SHOP.id;
        if (isPurchase) {
            return `Покупка ${currencyFormat(transaction.value)}р`;
        }
        const action: string = transaction.creditorId !== USER_ME.id ? 'ждёт' : 'торчит';
        return `${name} ${action} ${currencyFormat(transaction.value)}р`;
    }, [transaction, name]);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (!open)
            return;
        timeoutRef.current = setTimeout(() => {
            storeDispatch(updateRecent());
        }, RECENT_TRANSACTION_UPDATE_TIME);
    }, [open, storeDispatch]);

    return (
        <Stack spacing={2} sx={{width: '100%'}}>
            <Snackbar
                open={open}
                autoHideDuration={RECENT_TRANSACTION_VIEW_TIME}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <Alert onClose={handleClose} severity="success" color="info" sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    )
}

export default RecentTransactionSnackbar;
