import React, { useMemo, useState } from "react";
import { Transaction } from "../../interfaces";
import { useAppSelector } from "../../store/hooks";
import { selectTransactions } from "../../store/reducers/transactionSlice";
import { CATEGORIES } from "../../constants";
import Chart from "react-apexcharts";
import { currencyFormat } from "../../utils/currencyFormat";
import { getToday } from "../../utils/getToday";
import { PeriodSelector } from "../PeriodSelector/PeriodSelector";
import { getMonthlyAverageOfYear, isTransactionIn } from "./helpers";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ApexOptions = ApexCharts.ApexOptions;

const Statistics = () => {
    const today = useMemo(getToday, []);
    const [isMonthType, setIsMonthType] = useState<boolean>(true);
    const [month, setMonth] = useState<number>(today.getMonth());
    const [year, setYear] = useState<number>(today.getFullYear());
    const transactions: Transaction[] = useAppSelector(selectTransactions);

    const handlePeriodTypeChange = (event: React.SyntheticEvent, newValue: number) => {
        setIsMonthType(newValue === 0);
    };

    const { statMap, totalValue } = useMemo(() => {
        let totalValue = 0;
        const statMap: Map<number, number> = new Map(); // Map<categoryId, sum>
        transactions.forEach((transaction) => {
            const monthIfEnable = isMonthType ? month : undefined;
            if (transaction.categoryId && isTransactionIn(transaction, year, monthIfEnable)) {
                statMap.set(transaction.categoryId, (statMap.get(transaction.categoryId) || 0) + transaction.value);
                totalValue += transaction.value;
            }
        })
        const statMapNotEmpty = new Map(
            Array.from(statMap).filter(([, v]) => v > 0 )
        );
        return { statMap: statMapNotEmpty, totalValue };
    }, [isMonthType, transactions, month, year]);

    const options = {
        chart: {
            id: "basic-bar",
            background: 'rgba(0, 0, 0, 0.3)',
            foreColor: 'white',
        },
        xaxis: {
            categories: Array.from(statMap).map(([categoryId]) => {
                const category = CATEGORIES.find((category) => category.id === categoryId);
                return category?.title || 'Без категории';
            })
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: true,
            formatter: (value: number) => {
                if (isMonthType || today.getMonth() === 0) {
                    return currencyFormat(value);
                }
                const average = getMonthlyAverageOfYear(value, year, today);

                return `${currencyFormat(value)} (${currencyFormat(average)}р/мес)`;
            },
            textAnchor: 'start',
        },
        forForceUpdate: isMonthType,
    };

    const series = [
        {
            name: "series-1",
            data: Array.from(statMap).map(([, sum]) => sum)
        }
    ];

    const totalValueStr =`${currencyFormat(totalValue)}р`;
    const averageStr = isMonthType ? '' : ` (${currencyFormat(getMonthlyAverageOfYear(totalValue, year, today))}р/мес)`;
    const totalStr = `Всего: ${totalValueStr}${averageStr}`;

    return (
        <div className='historyPage'>
            <Tabs
                value={isMonthType ? 0 : 1}
                onChange={handlePeriodTypeChange}
                centered
                variant="fullWidth"
            >
                <Tab label='Месяц' />
                <Tab label='Год' />
            </Tabs>
            <PeriodSelector
                type={isMonthType ? 'month' : 'year'}
                year={year}
                setYear={setYear}
                month={month}
                setMonth={setMonth}
            />
            {totalStr}
            <Chart
                options={options as ApexOptions}
                series={series}
                type="bar"
            />
        </div>
    )
}

export default Statistics;
