import { styled } from "@mui/material/styles";
import { Event, EventHistory } from "../../../interfaces";
import { useAppSelector } from "../../../store/hooks";
import { selectEventHistory, selectEvents } from "../../../store/reducers/eventSlice";
import React, { FC } from "react";
import { getEventsByDay } from "../Calendar/helpers";
import { EventRow } from "./EventRow/EventRow";

export const Wrapper = styled('div')({
    flex: 1,
    width: '100%',
    padding: '0 0.5rem',
    overflow: 'auto',
});

interface DayEventsProps {
    date: Date;
}

export const DayEvents: FC<DayEventsProps> = ({ date }) => {
    const events: Event[] = useAppSelector(selectEvents);
    const eventHistory: EventHistory = useAppSelector(selectEventHistory);

    const dateISO = date.toISOString();
    const dateWithoutTimeISO = dateISO.slice(0, 10);
    const dayEvents = getEventsByDay(events, eventHistory, dateWithoutTimeISO);

    return <Wrapper>
        {dayEvents.map((value, i) => {
            if (!value) {
                return <div>Событие удалено</div>;
            }

            return <EventRow
                id={value.id}
                event={value.event}
                dateKey={dateWithoutTimeISO}
                count={value.count}
                key={`${value.event.id}_${i}`}
            />
        })}
    </Wrapper>;
}
