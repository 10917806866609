import { Transaction } from "../../interfaces";

export const isTransactionIn = (transaction: Transaction, year: number, month?: number): boolean => {
    if (!transaction.date) {
        return false;
    }
    const transactionDate = new Date(transaction.date);
    const transactionYear = transactionDate.getFullYear();
    if (transactionYear !== year) {
        return false;
    }
    if (month === undefined) {
        return true;
    }
    const transactionMonth = transactionDate.getMonth();

    return transactionMonth === month;
}

export const getMonthlyAverageOfYear = (value: number, year: number, today: Date): number => {
    const averageMonthsCount = year === today.getFullYear() ? today.getMonth() : 12;

    return Math.floor(value / averageMonthsCount);
}
