import React, { FC } from "react";
import { getCellStyle, getEventsByDay } from "../helpers";
import { DayCell } from "../styled";
import { Event, EventHistory } from "../../../../interfaces";
import { useAppSelector } from "../../../../store/hooks";
import { selectEventHistory, selectEvents } from "../../../../store/reducers/eventSlice";
import { Preview } from "./Preview/Preview";

interface CellProps {
    date: Date;
    isAnotherMonth: boolean;
    isToday: boolean;
    isSelected: boolean;
    isWeekend: boolean;
    onClick: () => void;
}

export const Cell: FC<CellProps> = ({ date, isAnotherMonth, isToday, isSelected, isWeekend, onClick }) => {
    const events: Event[] = useAppSelector(selectEvents);
    const eventHistory: EventHistory = useAppSelector(selectEventHistory);

    const dateISO = date.toISOString();
    const dateWithoutTimeISO = dateISO.slice(0, 10);
    const dayEvents = getEventsByDay(events, eventHistory, dateWithoutTimeISO);

    return (
        <DayCell
            onClick={onClick}
            style={getCellStyle(isAnotherMonth, isToday, isSelected, isWeekend)}
        >
            {date.getDate()}
            {dayEvents.map((value) => (
                <Preview event={value.event} />
            ))}
        </DayCell>
    )
}
