import { CSSProperties, FC, useEffect } from 'react'
import { useDrag, DragSourceMonitor } from 'react-dnd'
import { ItemTypes } from '../../ItemTypes'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { UserBox } from '../../UserBox/UserBox'

function getStyles(
    left: number,
    top: number,
    isDragging: boolean,
): CSSProperties {
    const transform = `translate3d(${left}px, ${top}px, 0)`
    return {
        position: 'absolute',
        transform,
        WebkitTransform: transform,
        // IE fallback: hide the real node using CSS when dragging
        // because IE will ignore our custom "empty image" drag preview.
        opacity: isDragging ? 0 : 1,
        height: isDragging ? 0 : '',
    }
}

export interface DraggableBoxProps {
    id: number
    title: string
    left: number
    top: number
}

export const DraggableBox: FC<DraggableBoxProps> = ({ id, title, left, top }) => {
    const [{ isDragging }, drag, preview] = useDrag(
        () => ({
            type: ItemTypes.BOX_MOVE,
            item: { id, left, top, title },
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [id, left, top, title],
    )

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    return (
        <div
            ref={drag}
            style={getStyles(left, top, isDragging)}
        >
            <UserBox userId={id} />
        </div>
    )
}
