import React, { useEffect, useState } from "react";
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import ControlPanel from "./ControlPanel/ControlPanel";
import Home from "./Home/Home";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import History from "./History/History";
import Statistics from "./Statistics/Statistics";
import MoveControl from "./ControlPanel/AddUserButton/MoveControl/MoveControl";
import PieChartIcon from '@mui/icons-material/PieChart';
import EventIcon from '@mui/icons-material/Event';
import { useIndexDB } from "../indexDB/useIndexDB";
import { SaveButton } from "../indexDB/SaveButton/SaveButton";
import { Events } from "./Events/Events";

const allTabs: string[] = [
    '/statistics',
    '/',
    '/history',
    '/events',
];

const defaultTabIndex = 3; //todo: 1

const Index = () => {
    const [tab, setTab] = useState<number>(defaultTabIndex);
    const location = useLocation();
    const { isLoading: isLoadingSave, needToSave, handleManualSave } = useIndexDB();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    useEffect(() => {
        const tabIndex = allTabs.findIndex((pathname) => pathname === location.pathname);
        if (tabIndex < 0) {
            setTab(defaultTabIndex);
            return;
        }
        setTab(tabIndex);
    }, [location.pathname]);

    return (
        <div className="layout__window">
            <div className="layout__header">
                <SaveButton isLoading={isLoadingSave} needToSave={needToSave} onClick={handleManualSave} />
                <MoveControl />
                <ControlPanel />
            </div>
            <div className="layout__container">
                <Switch>
                    <Route
                        exact
                        path={allTabs[0]}
                        component={Statistics}
                    />
                    <Route
                        exact
                        path={allTabs[1]}
                        component={Home}
                    />
                    <Route
                        exact
                        path={allTabs[2]}
                        component={History}
                    />
                    <Route
                        exact
                        path={allTabs[3]}
                        component={Events}
                    />
                </Switch>
            </div>
            <div className="layout__footer">
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    centered
                    variant="fullWidth"
                >
                    <Tab
                        to={allTabs[0]}
                        icon={<PieChartIcon />}
                        component={Link}
                    />
                    <Tab
                        to={allTabs[1]}
                        icon={<HomeIcon />}
                        component={Link}
                    />
                    <Tab
                        to={allTabs[2]}
                        icon={<FormatListBulletedIcon />}
                        component={Link}
                    />
                    <Tab
                        to={allTabs[3]}
                        icon={<EventIcon />}
                        component={Link}
                    />
                </Tabs>
            </div>
        </div>
    )
}

export default Index;
