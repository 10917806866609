import { styled } from "@mui/material/styles";

export const DivPreview = styled('div')({
    color: '#536078',
    height: '0.8rem',
    overflow: 'hidden',
    fontSize: '0.6rem',
    lineHeight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    margin: '1px',
    '&>*': {
        fontSize: '0.8rem',
    }
});
