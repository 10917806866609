import React, { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { selectMoveMode, toggleMoveMode } from '../../../../store/reducers/settingsSlice';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)({
    color: 'white'
});

const MoveControl = () => {
    const moveMode: boolean = useAppSelector(selectMoveMode);
    const storeDispatch = useAppDispatch();

    const onClick = useCallback(() => {
        storeDispatch(toggleMoveMode());
    }, [storeDispatch]);

    if (!moveMode) {
        return <div />
    }

    return (
        <StyledButton onClick={onClick}>
            Зафиксировать
        </StyledButton>
    )
}

export default MoveControl;