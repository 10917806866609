import { User } from "../../../../interfaces";
import { USER_ME } from "../../../../constants";

export const getCanDrop = (dragUser: User, dropUser: User) => {
    // if the user himself
    if (dropUser.id === dragUser.id)
        return false;
    const dragUserIsMe: boolean = dragUser.id === USER_ME.id;
    const dropUserIsMe: boolean = dropUser.id === USER_ME.id;
    // if is not me
    if (!dragUserIsMe) {
        // drop only to me
        return dropUserIsMe || false;
    }
    // if is me, drop anyone
    return true;
}