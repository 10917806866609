import React, { FC, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { setMenuAnchorEl } from '../../../store/reducers/settingsSlice'
import GitSettingsDialog from "./GitSettingsDialog/GitSettingsDialog";
import ListItemIcon from "@mui/material/ListItemIcon";
import GitHubIcon from "@mui/icons-material/GitHub";
import MenuItem from "@mui/material/MenuItem";

const GitSettingsButton: FC = () => {
    const storeDispatch = useAppDispatch();
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const handleCloseRenameDialog = () => {
        setOpenDialog(false);
        storeDispatch(setMenuAnchorEl(null));
    }

    return (
        <>
            <MenuItem onClick={() => setOpenDialog(true)}>
                <ListItemIcon>
                    <GitHubIcon fontSize="small" />
                </ListItemIcon>
                Git
            </MenuItem>
            <GitSettingsDialog
                open={openDialog}
                onClose={handleCloseRenameDialog}
            />
        </>
    )
}

export default GitSettingsButton;
