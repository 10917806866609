import * as React from 'react';
import { Transaction } from "../../../../interfaces";
import { useAppSelector } from "../../../../store/hooks";
import { selectRecentTransactions } from "../../../../store/reducers/transactionSlice";
import RecentTransactionSnackbar from "./RecentTransactionSnackbar/RecentTransactionsSnackbar";

export default function RecentTransactions() {
    const recentTransactions: Transaction[] = useAppSelector(selectRecentTransactions);

    return (<>
        {recentTransactions.map((transaction) => (
            <RecentTransactionSnackbar
                transaction={transaction}
                key={transaction.id}
            />
        ))}
    </>);
}
