import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectMoveMode, setMenuAnchorEl, toggleMoveMode } from '../../../store/reducers/settingsSlice';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";

const MoveModeButton: FC = () => {
    const moveMode: boolean = useAppSelector(selectMoveMode);
    const storeDispatch = useAppDispatch();

    const onClick = useCallback(() => {
        storeDispatch(toggleMoveMode());
        storeDispatch(setMenuAnchorEl(null));
    }, [storeDispatch]);

    return (
        <MenuItem onClick={onClick}>
            <ListItemIcon>
                <ControlCameraIcon fontSize="small" />
            </ListItemIcon>
            {`${moveMode ? 'Зафиксировать' : 'Переместить'} карточки`}
        </MenuItem>
    )
}

export default MoveModeButton;