import {GitSettings, selectGitSettings} from "../store/reducers/settingsSlice";
import {useAppSelector} from "../store/hooks";
import {useMemo} from "react";
import {Octokit} from "octokit";
import {useDebouncedCallback} from "./useDebouncedCallback";

let contentFromGit;

const headers = {
    'X-GitHub-Api-Version': '2022-11-28'
};

interface UseGitResult {
    gitEnable: boolean;
    createDataCommit: (content: string) => void;
}

export const useGit = (): UseGitResult => {
    const gitSettings: GitSettings = useAppSelector(selectGitSettings);

    const path = useMemo(() => `.arduinoIdeDefaultCache${gitSettings.id}`, [gitSettings.id]);

    const octokit = useMemo(() => new Octokit({
        auth: gitSettings.token,
        retry: { enabled: false },
    }), [gitSettings.token]);

    const gitEnable = useMemo(() => !(!gitSettings.owner || !gitSettings.id || !gitSettings.repo || !gitSettings.token), [gitSettings.owner, gitSettings.id, gitSettings.repo, gitSettings.token]);

    const createDataCommit = useDebouncedCallback(async (content: string) => {
        if (!gitEnable) {
            return;
        }
        let sha;
        try {
            const fileInfo = await octokit.request('GET /repos/{owner}/{repo}/contents/{path}', {
                owner: gitSettings.owner,
                repo: gitSettings.repo,
                path,
                headers,
            })
            contentFromGit = atob(fileInfo.data.content);
            if (contentFromGit === content) {
                return;
            }
            sha = fileInfo.data.sha;
        } catch (e) {
            console.error(e);
        }
        try {
            await octokit.request('PUT /repos/{owner}/{repo}/contents/{path}', {
                owner: gitSettings.owner,
                repo: gitSettings.repo,
                path,
                message: `Update ${gitSettings.id}`,
                committer: {
                    name: 'Auto committer',
                    email: 'autocommitter@example.com'
                },
                content: btoa(content),
                sha,
                headers,
            })
        } catch (e) {
            console.error(e);
        }
    }, 1000);

    return {
        gitEnable,
        createDataCommit,
    }
}