import React, { FC, useCallback } from "react";
import { useAppDispatch } from "../../../store/hooks";
import { resetAllCoords } from '../../../store/reducers/userSlice'
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import { setMoveMode, setMenuAnchorEl } from "../../../store/reducers/settingsSlice";

const ResetPositionsButton: FC = () => {
    const storeDispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        storeDispatch(resetAllCoords());
        storeDispatch(setMoveMode(true));
        storeDispatch(setMenuAnchorEl(null));
    }, [storeDispatch]);

    return (
        <MenuItem onClick={handleClick}>
            <ListItemIcon>
                <PictureInPictureIcon fontSize="small" />
            </ListItemIcon>
            Сбросить позиции карточек
        </MenuItem>
    )
}

export default ResetPositionsButton;