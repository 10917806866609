import React from "react";
import Grid from '@mui/material/Grid';
import { CATEGORIES } from "../../../../../../constants";

interface CategoryGridProps {
    onSelect: (categoryId: number) => void
}

const CategoryGrid = ({ onSelect }: CategoryGridProps) => {
    return (
        <div className='category-grid'>
            <Grid container>
                {CATEGORIES.map((category) => (
                    <Grid
                        item
                        xs={3}
                        className='category-item'
                        onClick={() => onSelect(category.id)}
                        style={{
                            background: category.color
                        }}
                        key={category.id}
                    >
                        {category.title}
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default CategoryGrid;