export function reloadWithoutCache () {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations()
            .then((registrations) => {
                for(let registration of registrations) {
                    registration.unregister();
                }
            });
    }
    if('caches' in window){
        caches.keys().then((names) => {
            names.forEach(name => {
                caches.delete(name);
            })
        });
    }
    // @ts-ignore
    window.location.reload(true);
}