import React, { MouseEvent, useCallback, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import { remove } from "../../../../../store/reducers/userSlice";
import { useAppDispatch } from "../../../../../store/hooks";

interface Props {
    userId: number
}

const ContextMenu = ({ userId }: Props) => {
    const storeDispatch = useAppDispatch();
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const handleDelete = useCallback(() => {
        handleCloseMenu();
        if (!window.confirm('Точно удалить?'))
            return;
        storeDispatch(remove(userId));
    }, [storeDispatch, userId]);

    return (
        <div className='userBox__contextMenu'>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={menuAnchorEl ? 'long-menu' : undefined}
                aria-expanded={menuAnchorEl ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpenMenu}
            >
                <MoreVertIcon className='userBox__contextMenuIcon' sx={{ fontSize: '1rem' }} />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl}
                onClose={handleCloseMenu}
                className=''
            >
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    Удалить
                </MenuItem>
            </Menu>
        </div>
    )
}

export default ContextMenu;