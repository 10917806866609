import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import { Event, EventHistory, Transaction, User } from "../../interfaces";
import { FC, useCallback, useState } from "react";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectUsers } from "../../store/reducers/userSlice";
import { selectTransactions } from "../../store/reducers/transactionSlice";
import { setMenuAnchorEl } from "../../store/reducers/settingsSlice";
import { getFileName } from "../../utils/getFileName";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import { selectEventHistory, selectEvents } from "../../store/reducers/eventSlice";
import { saveTextAsFile } from "../../utils/saveTextAsFile";
import {getProjectDataJsonString} from "../../utils/getProjectDataJsonString";

export const saveDBAsFile = (users: User[], transactions: Transaction[], events: Event[], eventHistory: EventHistory) => {
    const jsonStr = getProjectDataJsonString(users, transactions, events, eventHistory);
    saveTextAsFile(jsonStr, getFileName());
};

const Export: FC = () => {
    const users: User[] = useAppSelector(selectUsers);
    const transactions: Transaction[] = useAppSelector(selectTransactions);
    const events: Event[] = useAppSelector(selectEvents);
    const eventHistory: EventHistory = useAppSelector(selectEventHistory);
    const [loading, setLoading] = useState<boolean>(false);
    const storeDispatch = useAppDispatch();

    const handleExport = useCallback(() => {
        storeDispatch(setMenuAnchorEl(null));
        setLoading(true);
        saveDBAsFile(users, transactions, events, eventHistory);
        setLoading(false);
    }, [storeDispatch, users, transactions, events, eventHistory, setLoading]);

    if (loading)
        return <CircularProgress size='1rem' color='inherit' />;

    return (
        <MenuItem onClick={handleExport}>
            <ListItemIcon>
                <DownloadIcon fontSize="small" />
            </ListItemIcon>
            Сохранить в файл
        </MenuItem>
    );
}

export default Export;
