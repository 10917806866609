import CircularProgress from '@mui/material/CircularProgress';
import DownloadIcon from '@mui/icons-material/Download';
import { VERSION } from "../../constants";
import { Transaction } from "../../interfaces";
import { FC, useCallback, useState } from "react";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectOnlyCategorizedMinimalTransactions } from "../../store/reducers/transactionSlice";
import { setMenuAnchorEl } from "../../store/reducers/settingsSlice";
import { getFileName } from "../../utils/getFileName";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import { saveTextAsFile } from "../../utils/saveTextAsFile";

const saveTransactionsAsFile = (transactions: Transaction[]) => {
    const jsonStr = JSON.stringify({
        version: VERSION,
        transactions,
    });
    saveTextAsFile(jsonStr, getFileName({ onlyTransactions: true }));
};

const ExportTransactions: FC = () => {
    const transactions: Transaction[] = useAppSelector(selectOnlyCategorizedMinimalTransactions);
    const [loading, setLoading] = useState<boolean>(false);
    const storeDispatch = useAppDispatch();

    const handleExport = useCallback(() => {
        storeDispatch(setMenuAnchorEl(null));
        setLoading(true);
        saveTransactionsAsFile(transactions);
        setLoading(false);
    }, [storeDispatch, transactions, setLoading]);

    if (loading)
        return <CircularProgress size='1rem' color='inherit' />;

    return (
        <MenuItem onClick={handleExport} style={{ whiteSpace: 'normal' }}>
            <ListItemIcon>
                <DownloadIcon fontSize="small" />
            </ListItemIcon>
            Сохранить в файл транзакции с категориями
        </MenuItem>
    );
}

export default ExportTransactions;
