import { FC, CSSProperties, memo } from 'react'
import { UserBox } from '../../UserBox/UserBox'

const styles: CSSProperties = {
    display: 'inline-block',
    transform: 'rotate(-7deg)',
    WebkitTransform: 'rotate(-7deg)',
}

export interface BoxPreviewTransactionProps {
    userId: number
}

export const BoxPreviewTransaction: FC<BoxPreviewTransactionProps> = memo(
    function BoxPreviewTransaction({ userId }) {
        return (
            <div style={styles}>
                <UserBox userId={userId} preview previewTransaction />
            </div>
        )
    },
)
