import React, { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { CalendarWrapper, CalendarGrid, WeekRow } from "./styled";
import { getDatesByWeeksArray } from "./helpers";
import { Cell } from "./Cell/Cell";
import { PeriodSelector } from "../../PeriodSelector/PeriodSelector";
import { getToday } from "../../../utils/getToday";

interface Props {
    daySelected: Date;
    setDaySelected: Dispatch<SetStateAction<Date>>
}

export const Calendar: FC<Props> = ({ daySelected, setDaySelected }) => {
    const today = useMemo(() => getToday(), []);
    const [month, setMonth] = useState<number>(today.getMonth());
    const [year, setYear] = useState<number>(today.getFullYear());

    const renderDays = () => {
        const daysByWeeks = getDatesByWeeksArray(year, month);

        return (
            daysByWeeks.map((week, weekIndex) => (
                <WeekRow key={weekIndex}>
                    {week.map((date, dayIndex) => {
                        const dateISO = date.toISOString();
                        const dateWithoutTimeISO = dateISO.slice(0, 10);
                        const isAnotherMonth = date.getMonth() !== month;
                        const isToday = dateWithoutTimeISO === today.toISOString().slice(0, 10);
                        const isSelected = dateWithoutTimeISO === daySelected.toISOString().slice(0, 10);
                        const isWeekend = dayIndex === 5 || dayIndex === 6;

                        return (
                            <Cell
                                date={date}
                                isAnotherMonth={isAnotherMonth}
                                isToday={isToday}
                                isSelected={isSelected}
                                isWeekend={isWeekend}
                                onClick={() => setDaySelected(date)}
                                key={dateISO}
                            />
                        )
                    })}
                </WeekRow>
            ))
        );
    }

    return (
        <>
            <PeriodSelector type='month' month={month} year={year} setMonth={setMonth} setYear={setYear} />
            <CalendarWrapper>
                <CalendarGrid>
                    {renderDays()}
                </CalendarGrid>
            </CalendarWrapper>
        </>
    )
}
