import React, { useMemo } from "react";
import { DndProvider } from 'react-dnd'
import { TouchBackend } from "react-dnd-touch-backend";
import { useAppSelector } from "../../store/hooks";
import { selectMoveMode } from "../../store/reducers/settingsSlice";
import { ContainerMove } from "./ContainerMove/ContainerMove";
import { DragLayer } from "./DragLayer/DragLayer";
import { ContainerTransactions } from "./ContainerTransactions/ContainerTransactions";

const Home = () => {
    const moveMode: boolean = useAppSelector(selectMoveMode);

    const ContainerComponent = useMemo(() => {
        if (moveMode) {
            return (
                <>
                    <ContainerMove />
                    <DragLayer />
                </>
            )
        }

        return (
            <>
                <ContainerTransactions />
                <DragLayer />
            </>
        )
    }, [moveMode]);

    return (
        <DndProvider
            backend={TouchBackend}
            options={{
                enableMouseEvents: true,
                enableTouchEvents: true,
                preview: true,
            }}
        >
            {ContainerComponent}
        </DndProvider>
    );
}

export default Home;
